
import { useStore } from "vuex";
import { computed, onMounted ,onBeforeMount, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  components: {
    VeriTablo,
    EmptyModal,
    
  },
    setup() {
        const brandName = ref('');
        const color = ref('#563d7c');
        const slogan = ref('');
        const explanation = ref('');
        const content = ref('');

        const tableHeader = [
            {
                name: "Öğrenci Adı",
                key:'brandName'
            },
            {
                name: "Öğrenci Soyad",
                key: "slogan",
            },
            {
                name: "Tag",
                key: "tag",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];
        const deleteRecord = (brand) => {
            console.log(brand);
            // for (let i = 0; i < tableData.value.length; i++) {
            
            //     if (tableData.value[i] === brand) {
            //         console.log(brand);
            //     }
            // }
        };
        const store = useStore();
        const router = useRouter();


        let brands = computed(() => {
            return store.getters.allbrands;
        });
        let links = computed(() => {
            return store.getters.linkObject;
        });
         let metas = computed(() => {
            return store.getters.metaObject;
        });

        
        function saveMarka(){
            var brand = {
                "brandName": brandName.value,
                "slogan": slogan.value,
                "tag": "a",
                "color": color.value,
                "image": null,
                "explanation": null,
                "content": null
            }
            let promise = store.dispatch(Actions.SAVE_BRAND,brand);

            promise.then(({ data }) => {
                if(data.success){
                    console.log(data);
                    Swal.fire({
                        text: "Başarılı Bir Şekilde Ekledi!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Tamam!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        
                        window.location.reload();
                    });
                } 
            })
            .catch(({ response }) => {
                console.log(response);

            });
        }
        onMounted(() => {
            store.dispatch(Actions.LOAD_BRANDS);
        });
       
        return {
            tableHeader,
            brands,
            deleteRecord,
            brandName,
            color,
            slogan,
            explanation,
            content,
            saveMarka,
            links,
            metas,
        }
    }
}


